<template>
  <section class="full-height">

    <div class="columns is-multiline is-centered is-gapless is-marginless">
      
      <div class="column is-10 is-offset-1 is-mobile is-narrow mt2rem">
        <div v-if="companyData" class="has-text-grey is-size-2 is-size-4-mobile wa-underlined-title">
          Import Shifts: {{ companyData.companies_id }} - {{ companyData.companies_name }}
        </div>
        <a :href='`${WEB_ROOT}/files/example_shift_import.xlsx`' target='_blank' class="highlight">
          example excelfile
        </a>
      </div>
      
      <div class="column is-10 is-offset-1 is-mobile is-narrow mt2rem">
        <p class="content"><b>Select allowed Schedules</b>: {{ selectedSchedules.join(`, `) }}</p>
        <b-field>
            <b-select
                multiple
                native-size="8"
                v-model="selectedSchedules"
                @input="clearData">
                <option
                  v-for="schedule in scheduleData"
                  :value="schedule.id"
                  :key="`schedule_${schedule.id}`">
                  {{ schedule.id }}-{{ schedule.name }}
              </option>
            </b-select>
        </b-field>
      </div>
      
      <div v-if="selectedSchedules.length > 0" class="column is-10 is-offset-1 is-mobile is-narrow mb1rem">
        <div class="columns is-multiline is-gapless is-marginless">
          <div class="column is-11 is-offset-1">
            <input type="file" @change="uploadFile" ref="file" />
          </div>
          <div v-if="this.userFile" class="column is-11 is-offset-1">
            <b-field>
              <b-select 
                placeholder="Select tab:" 
                v-model="selectedExcelTab" 
                @input="clearData">
                <option
                    v-for="tab in selectedExcelTabList"
                    :value="tab.id"
                    :key="`excel_tab_${tab.id}`">
                    {{ tab.name }}
                  </option>
              </b-select>            
            </b-field>
          </div>
          <div v-if="this.userFile" class="column is-11 is-offset-1">
            <b-button class="button is-primary" @click="submitFile">Upload Excel-File</b-button>
          </div>   
        </div>
      </div>
    </div>
    
    
    <div>
         
    </div>
    
    <div v-if="shiftImportData.length > 0" class="is-mobile is-gapless is-centered is-multiline m2rem">
        <h1>Import Shifts rows: {{ shiftImportData.length }}</h1>
        <b-switch v-model="showOnlyErrors"  @input="filterImportData"> 
          {{ showOnlyErrors ? 'ErrosOnly' : 'All rows' }}          
        </b-switch>
        <b-table
            :data="shiftImportDataFiltered"
            :bordered="true"
            :striped="true"
            :narrowed="true"
            :loading="tableIsLoading"
            :mobile-cards="true">

            <b-table-column field="rownr" label="row" width="40" :td-attrs="columnTdAttrs" numeric v-slot="props" sortable centered searchable>
                {{ props.row.rownr }}
            </b-table-column>
            <b-table-column field="dateStr2" label="datum" width="40" :td-attrs="columnTdAttrs" numeric v-slot="props" sortable centered searchable>
                {{ props.row.dateStr2 }}
            </b-table-column>
            <b-table-column field="dienstcode" label="dienst code" width="40" :td-attrs="columnTdAttrs" numeric v-slot="props" sortable centered searchable>
                {{ props.row.dienstcode }}
            </b-table-column>
            <b-table-column field="bignr" label="bignr" width="40" :td-attrs="columnTdAttrs" numeric v-slot="props" sortable centered searchable>
                {{ props.row.bignr }}
            </b-table-column>
            <b-table-column field="userId" label="user Id" width="40" :td-attrs="columnTdAttrs" numeric v-slot="props" sortable centered searchable>
                <a :href='`${WEB_ROOT}/company/${companyId}/user/${props.row.userId}`' target='_blank'>{{ props.row.userId }}</a>
            </b-table-column>
            <b-table-column field="email" label="email" width="40" :td-attrs="columnTdAttrs" numeric v-slot="props" sortable centered searchable>
                {{ props.row.email }}
            </b-table-column>
            <b-table-column field="mobile" label="mobile" width="40" :td-attrs="columnTdAttrs" numeric v-slot="props" sortable centered searchable>
                {{ props.row.mobile }}
            </b-table-column>
            <b-table-column field="shiftId" label="shift Id" width="40" :td-attrs="columnTdAttrs" numeric v-slot="props" sortable centered searchable>
                {{ props.row.shiftId }}
            </b-table-column>
            <b-table-column field="vacancyId" label="vacancy Id" width="40" :td-attrs="columnTdAttrs" numeric v-slot="props" sortable centered searchable>
              <a :href='`${WEB_ROOT}/company/${companyId}/vacancy/${props.row.vacancyId}`' target='_blank' class="highlight">
                {{ props.row.vacancyId }}
              </a>
            </b-table-column>
            <b-table-column field="errorStr" label="errors" width="40" :td-attrs="columnTdAttrs" numeric v-slot="props" sortable centered searchable>
                {{ props.row.errorStr }}
            </b-table-column>

            
            <template #empty>
                <div class="has-text-centered">No records</div>
            </template>

        </b-table>
        
        <b-button v-if="shiftImportData.length > 0" size="is-large" @click="download_json()">Download json</b-button>         
        <b-button v-if="shiftImportData.length > 0" size="is-large" @click="submitFileDownload()">Download excel</b-button>         
      </div>
    
       
    
    <b-notification :closable="false">
      <b-loading :is-full-page="true" :active.sync="pageLoading"></b-loading>
    </b-notification>
  </section>
</template>

<script>

// import { Auth }             from '@/plugins/firebase/auth';
// import { DB }               from '@/plugins/firebase/db';
// import axios_clean          from 'axios';
import axios_api from '@/plugins/axios_api';

// import { Calc_TimeLeftMsg }         from '@/helpers/functions';
import { WEB_ROOT, API_ROOT } from '@/config/app.js';
// import { mapState } from 'vuex';

export default {
  name: "EmptyPage", 
  
         
  data() {
    return {
      pageLoading         : false,
      pageLoadingTimeout  : null,
      
      companyId           : null,
      companyData         : null,
      scheduleData        : null,
      codesData           : null,
      
      shiftImportData     : [],
      tableIsLoading      : false,
      
      selectedSchedules   : [],
      shiftImportDataFiltered: [],
      
      showOnlyErrors      : false,
            
      userFile            : null,
      
      selectedExcelTabList: [],
      selectedExcelTab    : 0,
      
      WEB_ROOT            : WEB_ROOT,
      API_ROOT            : API_ROOT,
    }
  },
  
  async mounted(){
    this.companyId = this.$route.params.companyId;
    
    for(let i=0;i<25;i++) {
      this.selectedExcelTabList.push({
        id: i,
        name: `Tab_${i}`
      })  
    }
    
    this.getCompanyData()
  },
  
  methods: {
    async getCompanyData() {
      // this.sho
      let response = await axios_api.get(`${API_ROOT}/schedule/company/${this.companyId}/Simple`, {});
      if (response.data.result !== 1) {
        this.$buefy.dialog.alert({ title: 'Error', message: 'unable to get company data', type: 'is-danger' })
        this.HidePageLoading()
        return;
      }
      this.companyData    = response.data.payload.companyData
      this.scheduleData   = response.data.payload.scheduleData
      this.codesData      = response.data.payload.codesData
      
      this.HidePageLoading()
    },
    uploadFile() {
      this.userFile = this.$refs.file.files[0];
    },
    
    
    async ShowPageLoading(maxDuration = 30000){
      this.pageLoading = true;
      
      if (this.pageLoadingTimeout !== null) {
        clearTimeout(this.pageLoadingTimeout);
      }
      
      this.pageLoadingTimeout = setTimeout(() => {
        this.HidePageLoading();
      }, maxDuration);
    },
    
    async HidePageLoading() {
      this.pageLoading = false;
      if (this.pageLoadingTimeout !== null) {
        clearTimeout(this.pageLoadingTimeout);
      }
    },
    
    FilterString (ThaString, FilterRules = /[^0-9]+/g, ReplaceWith = '--') {
      //examples /[^0-9]+/g   -- /[^A-Z0-9]+/g  -- /[^A-z0-9.]+/g  -- /[^A-z0-9.+]+/g  -- /[^a-zA-Z0-9-\s]+/g 
      if (typeof(ThaString) !== "string" || ThaString === "") {
        return false;
      }
      
      return ThaString.replace(FilterRules, ReplaceWith)
    },
    
    download_json(){
      let json = this.shiftImportData.filter((row) => {
        return row.hasError === false
      }).map((row) => {
        return {
          user_id: row.userId,
          shift_id: row.shiftId,
        }
      })
      
      let tabname = this.selectedExcelTabList.find((tab) => { return tab.id === this.selectedExcelTab})
      if (!tabname) tabname = 'tab_x'
      tabname = this.FilterString(tabname.name, /[^a-zA-Z0-9-\s]+/g)
      
      let name = `_toImport_company_${this.companyId}_tab_${tabname}_schedules_${this.selectedSchedules.join('-')}.json`
      const a = document.createElement('a');
      const type = name.split(".").pop();
      a.href = URL.createObjectURL( new Blob([JSON.stringify(json)], { type:`text/${type === "txt" ? "plain" : type}` }) );
      a.download = name;
      a.click();
      
    },
    
    async submitFileDownload() {
      if (this.selectedSchedules.length <=0) {
        return this.$buefy.dialog.alert({ title: 'Error', message: 'no schedule(s) selected', type: 'is-danger' })
      }
      
      
      if (!this.userFile) {
        return this.$buefy.dialog.alert({ title: 'Error', message: 'invalid file', type: 'is-danger' })
      }
      // console.info(`selected:`, this.selectedSchedules)
      
      this.ShowPageLoading(5000)          
      
      const formData = new FormData();
      formData.append('file', this.userFile);
      const headers = { 'Content-Type': 'multipart/form-data' };
      let res = await axios_api.post(
        `${API_ROOT}/schedule/company/${this.companyId}/import/shifts/scheduleList/${this.selectedSchedules.join(`,`)}/tab/${this.selectedExcelTab}/1`, 
        formData, { headers, responseType: 'blob' }
      )
      
      let tabname = this.selectedExcelTabList.find((tab) => { return tab.id === this.selectedExcelTab})
      if (!tabname) tabname = 'tab_x'
      tabname = this.FilterString(tabname.name, /[^a-zA-Z0-9-\s]+/g)
      
      let blob = new Blob([res.data], { type: res.headers['content-type'] });
      let link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = `import_company_${this.companyId}_tab_${tabname}_schedules_${this.selectedSchedules.join(`-`)}.xlsx`
      link.click()
      // console.info(`res:`, res)
      this.HidePageLoading()
    },
    
    clearData(){
      this.shiftImportData = []
      this.shiftImportDataFiltered = []
    },
    
    async submitFile() {
      this.clearData()
      
      if (this.selectedSchedules.length <=0) {
        return this.$buefy.dialog.alert({ title: 'Error', message: 'no schedule(s) selected', type: 'is-danger' })
      }
      
      
      if (!this.userFile) {
        return this.$buefy.dialog.alert({ title: 'Error', message: 'invalid file', type: 'is-danger' })
      }
      // console.info(`selected:`, this.selectedSchedules)
      
      this.ShowPageLoading(5000)          
      
      const formData = new FormData();
      formData.append('file', this.userFile);
      const headers = { 'Content-Type': 'multipart/form-data' };
      let response = await axios_api.post(`${API_ROOT}/schedule/company/${this.companyId}/import/shifts/scheduleList/${this.selectedSchedules.join(`,`)}/tab/${this.selectedExcelTab}/0`, formData, { headers })
      console.info(`response:`, response)
      this.HidePageLoading()
      
      if (response.data.result !== 1) {
        this.$buefy.dialog.alert({ title: 'Error', message: 'unable to upload template data', type: 'is-danger' })        
        return;
      }
      
      this.shiftImportData      = response.data.payload.processedData
      this.selectedExcelTabList = response.data.payload.excelTabInfo
      
      this.filterImportData()
      
      // console.info(`downloading:`, response.data.payload)
      // const filename = encodeURIComponent(response.data.payload.excelFilename);      
      // window.open(`${API_ROOT}/schedule/company/${this.companyId}/bigchecker/download?filename=${filename}`, '_blank')
      
      this.HidePageLoading()
    },
    
    filterImportData(){
      let list = []
      for(let row of this.shiftImportData) {
        if (this.showOnlyErrors === false || row.hasError !== false) {
          list.push(row)
        }
      }
      this.shiftImportDataFiltered = list
    },
    
    
    dateThAttrs(column) {
        return column.label === 'Date' ? {
            title: 'This title is sponsored by "th-attrs" prop',
            class: 'has-text-success'
        } : null
    },
    columnTdAttrs(row, column) {
        if (row.id === 'Total') {
            if (column.label === 'ID') {
                return {
                    colspan: 4,
                    class: 'has-text-weight-bold',
                    style: {
                        'text-align': 'left !important'
                    }
                }
            } else if (column.label === 'Gender') {
                return {
                    class: 'has-text-weight-semibold'
                }
            } else {
                return {
                    style: {display: 'none'}
                }
            }
        }
        return null
    }
    
    
    
  },
};
</script>

<style>
    .editor {
      width: 94%;
      margin-left: 3%;
      height: 1000px;
    }
  
    .inputheight1{
      /* background-color: red; */
      font-size: 14px;
      height: 600px;
    }
  
    .inputheight2{
      /* background-color: red; */
      height: 560px;
      font-size: 11px;
    }
    
    .MyButton{
      /* background-color: red; */
      width: 96%;
      margin-left: 2%;
      margin-top: 2%;
    }
    
    textarea {
      resize: none;
    }
    
    .editor {
      width: 94%;
      margin-left: 3%;
      height: 1000px;
    }
  
    .inputheight1{
      /* background-color: red; */
      font-size: 14px;
      height: 600px;
    }
    
    .inputheight2{
      /* background-color: red; */
      font-size: 14px;
      height: 300px;
    }
    
    .inputheight3{
      /* background-color: red; */
      font-size: 14px;
      height: 50px;
    }
    
    .inputheight4{
      /* background-color: red; */
      font-size: 14px;
      height: 150px;
    }
    .spacer{
      height: 50px;
      width: 100%;
    }
    
    .example_messages p{
      margin-top: 15px;
      padding: 2%;
    }
  

</style>